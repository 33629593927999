import PageComponent from "../components/Page/Page";

import { request } from "@utilities/graphql/request";
import { parse, stringify } from "flatted";
import { promises as fs } from "fs";
import { join } from "path";
import {
  DEFAULT_CONTENTFUL_INCLUDE,
  fetchEntries,
  getPageProps,
} from "../utils/cms/ContentfulService";
import { GetProgrammeImportData } from "./api/getProgrammeImportData";
import { GetSettings } from "./api/getSettings";
import { getCriticalPaths } from "@utilities/getCriticalPaths";

const HOMEPAGE_SLUG = "home";

export default function Page({ safeJson }) {
  const { globalContent, pageContent, settings, programmeData, preview } =
    parse(safeJson);

  return (
    <PageComponent
      props={{
        globalContent,
        pageContent,
        settings,
        programmeData,
        preview,
        canonicalUrl:
          pageContent.slug === HOMEPAGE_SLUG ? "/" : `/${pageContent.slug}`,
      }}
    />
  );
}

export async function getStaticPaths() {
  // Call an external API endpoint to get posts
  const pages = await fetchEntries({
    content_type: "content",
  });

  // T1 - EE Landing Pages
  const EELandingPages = await fetchEntries({
    content_type: "eeLandingPage",
  });

  // T3 - Content 2019 Page
  const Content2019Pages = await fetchEntries({
    content_type: "content2019Page",
  });

  // T4 - Story Pages
  const StoryPages = await fetchEntries({
    content_type: "storyPage",
  });

  // T6 - News Detail
  const NewsDetailPages = await fetchEntries({
    content_type: "newsDetail",
  });

  const EventsDetailPages = await fetchEntries({
    content_type: "eventsDetail",
  });

  // T7 - BSR Content Page
  const BSRContentPages = await fetchEntries({
    content_type: "bsrContentPage",
  });

  // T11 - Publication Details Pages
  const PublicationDetailPages = await fetchEntries({
    content_type: "publicationDetail",
  });

  // T16 - DPOPage
  const DPOPagePages = await fetchEntries({
    content_type: "dpoPage",
  });

  // T17 - Student Profile Detail
  const StudentProfileDetailPages = await fetchEntries({
    content_type: "studentProfileDetail",
  });

  // T22 - Contributor Profile Detail Pages
  const ContributorProfileDetailPages = await fetchEntries({
    content_type: "contributorProfileDetail",
  });
  // T30 - Faculty Profile Detail Pages
  const FacultyProfileDetailPages = await fetchEntries({
    content_type: "facultyProfileDetail",
  });

  //  - EMBAG Template Pages
  const EMBAGTemplatePages = await fetchEntries({
    content_type: "embagTemplate",
  });

  //  T23 - Cluster Template Pages
  const ClusterPages = await fetchEntries({
    content_type: "clusterPage",
  });

  //  T5 - Campaign 2019 Template Pages
  const Campaign2019Pages = await fetchEntries({
    content_type: "campaignLandingPage2019",
  });

  const InteractSSO = await fetchEntries({
    content_type: "interactSso",
  });

  const PDFAsset = await fetchEntries({
    content_type: "pdfAsset",
  });

  //  - Programme Details Pages
  const programmeDetailsPages = await fetchEntries({
    content_type: "programmeDetails",
  });
  // - Research Details Pages
  const researchDetailsPage = await fetchEntries({
    content_type: "ResearchDetailsPage",
  });

  const allPages = ([] as any[]).concat(
    DPOPagePages,
    EELandingPages,
    FacultyProfileDetailPages,
    ContributorProfileDetailPages,
    NewsDetailPages,
    InteractSSO,
    pages,
    PublicationDetailPages,
    StoryPages,
    BSRContentPages,
    StudentProfileDetailPages,
    EventsDetailPages,
    Content2019Pages,
    EMBAGTemplatePages,
    ClusterPages,
    Campaign2019Pages,
    programmeDetailsPages,
    researchDetailsPage,
    PDFAsset
  );

  // Critical pages
  const criticalPaths: Array<string> = await getCriticalPaths();

  let paths = allPages
    ?.map((page) => {
      const slugArray = page.fields.slug.split("/");
      if (page.fields.slug === "404" || page.fields.slug === "home") {
        return null;
      }
      if (!criticalPaths.includes(page.fields.slug)) {
        return null;
      }

      return { params: { slug: slugArray } };
    })
    .filter((here) => here);

  return { paths, fallback: "blocking" };
}

export async function getStaticProps({ params, preview }) {
  const { slug } = params;
  let props: any;
  const globalsFile = await fs.readFile(
    join(process.cwd(), "pages/globals.json"),
    {
      encoding: "utf8",
    }
  );
  const globals = parse(globalsFile);

  const {
    programmeSettingsCollection,
    siteSettingsCollection,
    publicationSettingsCollection,
    courseTypeSettingCollection,
    errorMessageListCollection,
  } = await request(GetSettings, { preview });
  const programmeSettings = programmeSettingsCollection.items[0];
  const siteSettings = siteSettingsCollection.items[0];
  const publicationSettings = publicationSettingsCollection.items[0];
  const courseTypeSettings =
    courseTypeSettingCollection.items[0].linkedFrom.courseTypeSettingsCollection
      .items[0].coursesCollection.items;

  const globalErrorMessages = errorMessageListCollection;

  const settings = {
    programmeSettings,
    siteSettings,
    globalErrorMessages,
    publicationSettings,
    courseTypeSettings: {
      courses: courseTypeSettings,
    },
  };

  let programmeData = {};

  try {
    props = await getPageProps({
      slug: !!slug ? slug.join("/") : HOMEPAGE_SLUG,
      contentType: [
        "dpoPage",
        "bsrContentPage",
        "content",
        "content2019Page",
        "contributorProfileDetail",
        "eeLandingPage",
        "interactSso",
        "eventsDetail",
        "facultyProfileDetail",
        "newsDetail",
        "publicationDetail",
        "storyPage",
        "studentProfileDetail",
        "embagTemplate",
        "clusterPage",
        "campaignLandingPage2019",
        "programmeDetails",
        "ResearchDetailsPage",
        "pdfAsset",
      ],
      globals: [],
      include: DEFAULT_CONTENTFUL_INCLUDE,
      allTags: globals?.tags || null,
      preview,
    });

    props.globalContent = {
      ...props.globalContent,
      ...globals,
    };

    // If this is a programme page
    const programmeType = props?.pageContent?.programmeTypeCode;
    if (programmeType) {
      let today = new Date().toISOString();
      const { programmeImportDataCollection } = await request(
        GetProgrammeImportData,
        {
          variables: { programmeType, today },
        }
      );

      programmeData = programmeImportDataCollection.items[0] ?? {};
    }
  } catch (e) {
    return {
      notFound: true,
    };
  }

  // https://github.com/vercel/next.js/discussions/10992#discussioncomment-103826
  const safeJson = stringify({
    ...props,
    settings,
    programmeData,
    ...(!!preview && { preview: true }),
  });
  return {
    // Revalidate 4 times a day
    revalidate: 60 * 60 * 6,
    props: {
      safeJson,
    },
  };
}
